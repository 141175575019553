import React from 'react'
import styled from 'styled-components'
import { Layout } from '../components/common'
import SEO from '../components/common/SEO'
import Header from '../components/theme/Header'
import Footer from '../components/theme/Footer'
import CarouselCustom from "../components/common/IndexPage/carousel"
import ApartmentPreview from '../components/common/IndexPage/apartmentPreview'

import img1 from '../../static/carousel/01.jpg';
import img2 from '../../static/carousel/02.jpg';
import img3 from '../../static/carousel/03.jpg';
import img4 from '../../static/carousel/04.jpg';
import img5 from '../../static/carousel/05.jpg';
import img6 from '../../static/carousel/06.jpg';
import img7 from '../../static/carousel/07.jpg';
import img8 from '../../static/carousel/08.jpg';
import img9 from '../../static/carousel/09.jpg';
import img10 from '../../static/carousel/10.jpg';
import img11 from '../../static/carousel/11.jpg';
import img12 from '../../static/carousel/12.jpg';

let imgArray = [img1, img2, img3, img4, img5 ,img6, img7, img8, img9, img10, img11, img12];

const comfort = {
	name: "Comfort",
	image: require('../../static/Comfort/front_image/front_image.jpg'),
	link: 'comfort'
}

const coral = {
	name: "Coral",
	image: require('../../static/Coral/front_image/front_image.jpg'),
	link: 'coral'
}

const marina = {
	name: "Marina",
	image: require('../../static/Marina/front_image/front_image.jpg'),
	link: 'marina'
}

const perfect = {
	name: "Perfect",
	image: require('../../static/Perfect/front_image/front_image.jpg'),
	link: 'perfect'
}

const MainContentHolder = styled.div`

`

const ApartmentsHolder = styled.div `
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.preview-holder {
		&:nth-child(2) {
			margin: 0 30px 36px;
		}
	}

	@media screen and (max-width: 1186px) {
		justify-content: space-evenly;
	}

	@media screen and (max-width: 816px) {
		.preview-holder {
			&:nth-child(2) {
				margin: 0 0 36px;
			}
		}
	}
`

const IndexPage = () => (
	<Layout>
		<React.Fragment>
			<SEO title="Main" />
			<Header />
			<MainContentHolder className="main-content-holder">
				<CarouselCustom imgArray={imgArray} />
				<ApartmentsHolder className="main-apartments-list-holder">
					<ApartmentPreview value={comfort} />
					<ApartmentPreview value={coral} />
					<ApartmentPreview value={marina} />
					<ApartmentPreview value={perfect} />
				</ApartmentsHolder>
			</MainContentHolder>
			<Footer />
		</React.Fragment>
	</Layout>
)

export default IndexPage
